<template>
  <v-tooltip
    v-bind="$props"
    v-model="isShowingTooltip"
    class="v-tooltip"
    :class="{ 'v-tooltip--transparent': transparent }"
    activator="parent"
    close-on-content-click
    :z-index="tooltipZIndex"
  >
    <slot />
  </v-tooltip>
</template>

<script src="./vTooltip.js"></script>

<style lang="scss">
.v-tooltip {
  cursor: pointer;

  & > .v-overlay__content {
    pointer-events: auto;
    font-size: 1.2rem;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &--transparent {
    .v-overlay__content {
      opacity: .8;
    }
  }
}
</style>
