<template>
  <div class="navigation-drawer">
    <v-navigation-drawer
      v-model="isShowingDrawer"
      border="0"
      class="navigation-drawer__drawer"
      :class="{
        'navigation-drawer__drawer--is-medium': isMediumViewport,
        'navigation-drawer__drawer--is-large': isLargeViewport,
        'navigation-drawer__drawer--is-x-large-and-up': isXLargeAndUpViewport,
      }"
      scrim
      :rail="isLargeViewport || isXLargeAndUpViewport ? isShowingRail : false"
      :rail-width="70"
      :width="284"
      :temporary="isMediumViewport"
    >
      <div class="navigation-drawer__header">
        <v-app-bar-nav-icon
          class="navigation-drawer__hamburger"
          data-test="navbar-mobile-hamburger-icon-close"
          flat
          size="70"
          rounded="0"
          @click.stop="toggleDrawer"
        />
        <router-link
          data-test="logo"
          :to="routes('dashboard')"
        >
          <img
            class="navigation-drawer__logo"
            :src="fullSvg"
          />
        </router-link>
      </div>

      <div class="navigation-drawer__body">
        <v-list class="navigation-drawer__list">
          <div class="navigation-drawer__list--top">
            <v-list-item
              v-for="(link, index) in links"
              :key="`navigation-drawer__item--${index}`"
              :value="link"
              :to="link.url"
              :data-test="`navbar-tab-${link.icon}`"
              :active="link.url === $route.path"
              @click="closeDrawer"
            >
              <VTooltip
                v-if="isShowingRail"
                :location="'right'"
              >
                {{ $t(...link.text) }}
              </VTooltip>

              <template #prepend>
                <IconComponent :name="link.icon" />
              </template>

              <v-list-item-title>
                {{ $t(...link.text) }}
              </v-list-item-title>
            </v-list-item>
          </div>
          <div class="navigation-drawer__list--bottom">
            <v-list-item>
              <VTooltip
                v-if="isShowingRail"
                :location="'right'"
              >
                {{ userName }}
              </VTooltip>

              <template #prepend>
                <ProfileAvatar
                  class="navigation-drawer__profile-avatar"
                  is-small
                />
              </template>

              <v-list-item-title>
                <span class="navigation-drawer__user-name">{{ userName }}</span>

                <VTooltip
                  location="bottom"
                  class="navigation-drawer__tooltip"
                >
                  {{ userName }}
                </VTooltip>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              data-test="support-button"
              @click="redirectToSupportPage"
            >
              <VTooltip
                v-if="isShowingRail"
                location="right"
                class="navigation-drawer__tooltip"
              >
                {{ $t('general.words.help') }}
              </VTooltip>
              <template #prepend>
                <IconComponent name="help" />
              </template>

              <v-list-item-title>
                {{ $t('general.words.help') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              :active="routes('settings') === $route.path"
              data-test="settings-btn"
              :value="routes('settings')"
              :to="routes('settings')"
              @click="closeDrawer"
            >
              <VTooltip
                v-if="isShowingRail"
                location="right"
                class="navigation-drawer__tooltip"
              >
                {{ $t('general.titles.settings') }}
              </VTooltip>
              <template #prepend>
                <IconComponent name="settings" />
              </template>

              <v-list-item-title>
                {{ $t('general.titles.settings') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              data-test="logout-button"
              @click="showLogoutModal"
            >
              <VTooltip
                v-if="isShowingRail"
                location="right"
                class="navigation-drawer__tooltip"
              >
                {{ $t('general.actions.logout') }}
              </VTooltip>
              <template #prepend>
                <IconComponent name="logOut" />
              </template>

              <v-list-item-title>
                {{ $t('general.actions.logout') }}
              </v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
      </div>
    </v-navigation-drawer>
    <ModalComponent
      v-if="isLogoutTriggered"
      :is-displayed="isLogoutTriggered"
      type="small"
      @hide-modal="hideLogoutModal"
    >
      <template #body>
        {{ $t('components.navigationComponent.logoutConfirmation') }}
      </template>
      <template #footer>
        <ActionButton
          :action="logout"
          data-test="accept-logout"
        >
          {{ $t('components.navigationComponent.confirmLogout') }}
        </ActionButton>
        <ActionButton
          is-secondary
          :action="hideLogoutModal"
        >
          {{ $t('general.actions.cancel') }}
        </ActionButton>
      </template>
    </ModalComponent>
  </div>
</template>

<script src="./navigationDrawer.js" />

<style lang="scss">
.navigation-drawer {
  &__drawer {
    top: 0 !important;
    height: 100vh !important;
    z-index: map-get($zindex, navigation) !important;

    @include media-breakpoint-up(md) {
      top: 7rem !important;
      height: calc(100vh - $top-navigation-height) !important;
      box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.10), 0px 4px 6px -1px rgba(16, 24, 40, 0.10);

      &--is-large.v-navigation-drawer--rail,
      &--is-x-large-and-up.v-navigation-drawer--rail {
        box-shadow: none;
      }

      &--is-x-large-and-up {
        box-shadow: none;
      }

      .v-navigation-drawer__rail {
        .v-list-item-title {
          padding-left: 2.4rem;
        }

        .v-list-item-title {
          padding-left: 0;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $top-navigation-height;
    padding-right: 2.4rem;
    border-bottom: .1rem solid $neutral-20;
    border-right: .1rem solid $neutral-20;

    @include media-breakpoint-up(md) {
      display: none;
    }

    .v-list,
    .v-list-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;
      padding-inline: 0 !important;
      height: 3.6rem;
    }

    .v-list-item {
      justify-content: space-between;
    }
  }

  &__hamburger {
    color: $brand-50;

    .v-icon {
      --v-icon-size-multiplier: 1.2;
    }
  }

  &__logo {
    width: 11.4rem;
  }

  &__body {
    border-right: .1rem solid $neutral-20;
    background-color: $neutral-0;

    @include media-breakpoint-up(lg) {
      background-color: $neutral-10;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: .8rem;
    height: calc(100dvh - $top-navigation-height);
    width: calc(100% + .1rem);
    padding: .8rem 0 3.2rem;

    &--bottom {
      border-top: .1rem solid $neutral-40;

      .v-list-item {
        &:first-child {
          height: auto;
          max-height: max-content;
        }
      }
    }

    .v-list-item {
      height: 4rem;
      max-height: 4rem;
      min-height: 4rem;
      padding: .8rem .8rem .8rem 2.4rem;
      padding-inline: 2.4rem !important;
      margin-bottom: .8rem;

      &:last-child {
        margin-bottom: 0;
      }

      &--active {
        background-color: $primary-20;
        border-right: .2rem solid $brand-50;

        .icon-component {
          color: $primary-100;
        }
      }

      .v-list-item-title {
        font-size: 1.6rem;
        line-height: 3rem;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    .icon-component {
      display: flex;
      color: $brand-50;
    }
  }

  &__profile-avatar {
    .profile-avatar__avatar {
      margin: 0;
      color: $neutral-0;
    }
  }

  &__user-name {
    color: $neutral-100;
    font-weight: $font-weight-semi-bold;
    font-size: 1.8rem;
    margin-left: .8rem;
  }

  .v-list-item__prepend {
    width: 2.4rem;
    justify-content: center;
    margin-right: .8rem;
  }
}
</style>
