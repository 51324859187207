import { mapGetters } from 'vuex';

import routes from '@/router/routes';
import logout from '@/helpers/logout';
import redirectToSupportPage from '@/helpers/redirectSupportPage';

import fullSvg from 'Static/logos/full.svg';

import {
  VAppBarNavIcon, VNavigationDrawer, VList, VListItem, VListItemTitle,
} from 'vuetify/components';

import ActionButton from 'Components/buttons/actionButton/ActionButton.vue';
import IconComponent from 'Components/icons/iconComponent/IconComponent.vue';
import ModalComponent from 'Components/modals/modalComponent/ModalComponent.vue';
import ProfileAvatar from 'Components/profileAvatar/ProfileAvatar.vue';
import VTooltip from 'Components/vTooltip/VTooltip.vue';

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    links: {
      type: Array,
      default: () => [],
    },
    isShowingRail: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'show-rail'],

  components: {
    ActionButton,
    IconComponent,
    ModalComponent,
    ProfileAvatar,
    VAppBarNavIcon,
    VList,
    VListItem,
    VListItemTitle,
    VNavigationDrawer,
    VTooltip,
  },

  computed: {
    ...mapGetters('auth', ['userName']),

    isMediumViewport() {
      return this.$vuetify.display.md;
    },

    isLargeViewport() {
      if (this.$vuetify.display.lgAndUp) this.$emit('update:modelValue', true);
      return this.$vuetify.display.lg;
    },

    isXLargeAndUpViewport() {
      if (this.$vuetify.display.xlAndUp) this.$emit('show-rail', false);
      return this.$vuetify.display.xlAndUp;
    },
  },

  data() {
    return {
      fullSvg,
      isLogoutTriggered: false,
      isShowingDrawer: this.modelValue,
      logout,
      redirectToSupportPage,
      routes,
    };
  },

  watch: {
    modelValue(newValue) {
      this.isShowingDrawer = newValue;
    },

    isShowingDrawer(newValue) {
      this.$emit('update:modelValue', newValue);
    },
  },

  methods: {
    closeDrawer() {
      if (this.isLargeViewport && !this.isShowingRail) this.$emit('show-rail', true);
    },
    hideLogoutModal() {
      this.isLogoutTriggered = false;
    },

    showLogoutModal() {
      this.isLogoutTriggered = true;
    },

    toggleDrawer() {
      this.$emit('update:modelValue');
    },

  },

};
