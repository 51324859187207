import variables from 'Assets/scss/modules/_components.module.scss';
import isEscapeKeyPressed from '@/helpers/isEscapeKeyPressed';

import {
  VTooltip,
} from 'vuetify/components';

export default {
  components: {
    VTooltip,
  },

  props: {
    location: {
      type: String,
      default: 'bottom',
    },
    maxWidth: {
      type: String,
      default: 'auto',
    },
    openOnClick: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isShowingTooltip: false,
      tooltipZIndex: variables.tooltipZIndex,
    };
  },

  methods: {
    handleEscapeKey(event) {
      if (isEscapeKeyPressed(event)) {
        event.stopPropagation();
        this.hideTooltip();
      }
    },

    hideTooltip() {
      this.isShowingTooltip = false;
    },
  },

  watch: {
    isShowingTooltip(isShowing) {
      if (isShowing) document.addEventListener('keydown', this.handleEscapeKey, true);
      else document.removeEventListener('keydown', this.handleEscapeKey, true);
    },
  },
};
