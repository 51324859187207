<template>
  <div
    class="modal transition-slow-fade"
    :class="{
      'modal--small': type === 'small',
      'modal--align-left': alignLeft,
      'transition-slow-fade--active': isDisplayed,
    }"
    @click.prevent="hideModal"
  >
    <div
      class="modal__container"
      @click.stop
    >
      <button
        class="modal__close-button"
        data-test="close-modal"
        type="button"
        @click.prevent="hideModal"
      >
        <IconComponent
          name="close"
        />
      </button>
      <header
        v-if="type !== 'small'"
        class="modal__header"
      >
        <slot name="header">
          <h1 class="modal__title h2">
            {{ title }}
          </h1>
        </slot>
      </header>
      <div class="modal__content-inner">
        <div
          v-if="isBodyPresent"
          class="modal__content-body"
          data-test="modal-body"
        >
          <slot name="body" />
        </div>
        <footer
          v-if="isFooterPresent"
          class="modal__footer"
        >
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </div>
</template>

<script src="./modalComponent.js"></script>

<style lang="scss">
  .modal {
    top: 0;
    left: 0;
    background-color: rgba($neutral-100,0.2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    perspective: 400px;

    &__content-inner {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__content-body {
      height: 100%;
    }

    &__container {
      display: flex;
      margin-top: 10vh;
      margin-bottom: 10vh;
      flex-direction: column;
      min-height: 50vh;
      position: relative;
      width: 90%;
      padding: 3rem 2rem;
      background: $neutral-0;
      border-radius: $radius-default;
    }

    &__close-button {
      position: absolute;
      top: 2rem;
      right: 2rem;
      cursor: pointer;

      &:hover {
        .icon-component {
          transform: rotate(90deg);
        }
      }

      .icon-component {
        transition: transform .3s;
        display: flex;
        align-self: center;
        svg {
          fill: $new-neutral-50,
        }
      }
    }

    &__title {
      color: $brand-50;
      text-align: center;
      margin: 3rem 0 4rem 0;
    }

    &__footer {
      margin-top: 3rem;

      .btn {
        margin-bottom: 1rem;
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        .btn {
          margin-bottom: 0;
          width: auto;
        }
      }
    }

    &.modal--small {
      .modal__container {
        min-height: 0;
        align-self: center;
        margin-top: 0;
        max-width: 45rem;
        padding: ($spacing-default * 5) ($spacing-default * 4) ($spacing-default * 4);
      }

      .modal__close-button {
        top: 1.5rem;
        right: 1.5rem;
      }
    }
    &--align-left {
      .modal__title {
        text-align: left;
      }

      .modal__footer {
        flex-direction: row;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .modal {
      &__container {
        width: 50%;
        max-width: 75rem;
        padding: $spacing-default * 6;

        &--small {
          width: 45rem;
          padding: $spacing-default * 3;
          min-height: 50vh;
          align-self: center;
          margin-top: 0;
        }
      }

      &__title {
        margin-top: 2rem;
      }

      &.modal--small {
        .modal__container {
          min-height: 0;
          align-self: center;
          margin-top: 0;
        }
      }
    }
  }
</style>
